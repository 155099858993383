.scansListContainer {
  position: absolute;
  top: 0px;
  left: 79px;
  z-index: -200px;
  width: calc(100vw -79px);
  height: 100vh;
  overflow: hidden;
}

.inline {
  display: flex;
}

.block {
  display: block;
}

.borderLeft {
  border-left: 2px solid #34383b;
  margin-left: 0px;
  padding-left: 1px;
}

.borderTop {
  border-top: 2px solid #34383b;
  margin-top: 0px;
  padding-top: 1px;
}

.hidden {
  display: none;
}

.noImageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: large;
}

.noScans,
.centered,
.stillProcessing {
  max-width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.stillProcessingText {
  width: 250px;
}

.stillProcessingImage {
  width: 150px;
  color: #a8a8a8;
}
