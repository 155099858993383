.centered {
  display: block;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 90vh;
  overflow: hidden;
  padding-top: 25vh;
}

.centered img {
  margin-bottom: 16px;
  max-height: 256px;
}
